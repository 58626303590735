import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { CAPTCHA_KEY } from "gatsby-env-variables"

function SEO({ description, lang, meta, title, image, keywords, faviconHref }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          name: "image",
          content: image,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:image",
          content: image,
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:url",
          content: "https://renegociacao.itau.com.br/",
        },
      ].concat(meta)}
      link={[
        {
          rel: "shortcut icon",
          type: "image/png",
          href: `${faviconHref}`,
          sizes: "16x16",
        },
        {
          rel: "sitemap",
          type: "application/xml",
          href: "/sitemap-0.xml",
        },
      ]}
      script={[
        {
          type: "text/javascript",
          id: "recaptcha",
          src: `https://www.google.com/recaptcha/enterprise.js?render=${CAPTCHA_KEY}`,
        },
      ]}
    ></Helmet>
  )
}

SEO.defaultProps = {
  lang: "pt-br",
  meta: [],
  title: "Renegociação de dívidas de forma fácil | Banco Itaú",
  description:
    "Feirão limpa nome! Negocie suas dívidas e aproveite condições especiais para limpar o nome em até 5 dias.",
  keywords:
    "Negociação de dívidas, limpar o nome, renegociar dívida, aumentar score, itaú, renegociação itaú, renegocie itaú, negociar dívida, acordo itaú, renegocie fácil itaú",
  image: "/images/hero_desk.png",
  faviconHref: "/Favicon.png",
}

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
