import format from "@mobi/utils/formatters/number"
import dateFormat from "@mobi/utils/formatters/date"
import storage from "utils/storage"
import { convertStringToNumber } from "utils/convertStringToNumber"

const formatValue = (value) =>
  format({
    value: Number(value),
    style: "currency",
  }).substring(3)

const handleDesenrola = (contractCustomData = {}, isInstallment = false) => {
  let status

  if (isInstallment) {
    status = contractCustomData.offer?.find(
      ({ offer_type }) => offer_type === "P"
    ).restriction_code
  } else {
    status = contractCustomData.offer?.find(
      ({ offer_type }) => offer_type === "A"
    ).restriction_code
  }

  return {
    isDesenrola: contractCustomData.is_desenrola || false,
    status,
  }
}

export const FormatDebtsGroups = (
  installment_debts_group,
  isHotDeal,
  selectedPaymentPlans,
  hot_deal_simulation_response = {}
) => {
  return installment_debts_group?.map((cashGroup, index) => {
    const { recommended_installments_number: recommendedInstallmentNumber } =
      cashGroup

    let paymentPlanObj = {}

    const paymentPlanSelected = () => {
      if (selectedPaymentPlans) {
        const { selectedPaymentPlan, hasUnavailableInstallmentAtDate } =
          selectedPaymentPlans.filter(({ contracts }) =>
            cashGroup.contracts.find(
              ({ external_contract_id, contract_number = null }) =>
                contracts.find(
                  ({ id }) =>
                    id ===
                    (external_contract_id
                      ? external_contract_id
                      : contract_number)
                )
            )
          )[0]

        paymentPlanObj.installment_number =
          selectedPaymentPlan.installmentNumber
        paymentPlanObj.installment_value = selectedPaymentPlan.installmentValue
        paymentPlanObj.total_value = selectedPaymentPlan.totalValue
        paymentPlanObj.total_debt_value = selectedPaymentPlan.totalDebtValue
        paymentPlanObj.is_interest_free = selectedPaymentPlan.interestFree

        const recomendedInstallmentOffer = cashGroup.payment_plan.find(
          (payment) =>
            payment.installment_number ===
            cashGroup.recommended_installments_number
        )

        const selectedInstallmentOffer = cashGroup.payment_plan.find(
          (payment) =>
            payment.installment_number === selectedPaymentPlan.installmentNumber
        )

        paymentPlanObj.hasUnavailableInstallmentAtDate =
          hasUnavailableInstallmentAtDate

        const { plan_id } =
          selectedInstallmentOffer ||
          recomendedInstallmentOffer ||
          cashGroup.payment_plan[cashGroup.payment_plan.length - 1]

        paymentPlanObj.plan_id = plan_id
      } else if (isHotDeal) {
        const hotDealInstallment =
          hot_deal_simulation_response?.summaries[0]?.installment_number

        paymentPlanObj =
          cashGroup.payment_plan.filter(
            (payment) => payment.installment_number === hotDealInstallment
          )?.[0] || cashGroup.payment_plan[0]
      } else if (recommendedInstallmentNumber) {
        const recommendedPaymentPlan = cashGroup.payment_plan.find(
          (payment) =>
            payment.installment_number === recommendedInstallmentNumber
        )
        paymentPlanObj = recommendedPaymentPlan
      } else {
        paymentPlanObj = [...cashGroup.payment_plan].pop()
      }

      return paymentPlanObj
    }

    const contractsResponse = storage.session.getItem("contractsResponse")
    const renegContracts = contractsResponse?.origins?.reneg

    const selectedPaymentPlan = paymentPlanSelected()

    const selectedTotalDebtValue = selectedPaymentPlans
      ? selectedPaymentPlan.total_debt_value
      : formatValue(selectedPaymentPlan.total_debt_value)

    const selectedTotalValue = selectedPaymentPlans
      ? selectedPaymentPlan.total_value
      : formatValue(selectedPaymentPlan.total_value)

    const groupOriginalValue = recommendedInstallmentNumber
      ? selectedTotalDebtValue
      : formatValue(cashGroup.initial_value)

    const groupCurrentValue = recommendedInstallmentNumber
      ? selectedTotalValue
      : formatValue(cashGroup.value)

    return {
      simulationId: cashGroup.simulation_id,
      title: `Grupo ${index + 1}`,
      originalValue: groupOriginalValue,
      currentValue: groupCurrentValue,
      percent: Math.trunc(Number(cashGroup.percentual_discount)),
      availableDates: cashGroup.due_date.map((date) => dateFormat(date)),
      defaultDate: dateFormat(cashGroup.default_date),
      selectedDate: dateFormat(cashGroup.default_date),
      maxDate: dateFormat(cashGroup.max_date),
      minDate: dateFormat(cashGroup.min_date),
      hasUnavailableInstallmentAtDate:
        selectedPaymentPlan.hasUnavailableInstallmentAtDate,
      selectedPaymentPlan: {
        id: selectedPaymentPlan.plan_id,
        installmentNumber: selectedPaymentPlan.installment_number,
        isRecommendedInstallment:
          selectedPaymentPlan.installment_number ===
          recommendedInstallmentNumber,
        installmentValue: selectedPaymentPlans
          ? selectedPaymentPlan.installment_value
          : format({
              value: Number(selectedPaymentPlan.installment_value),
              style: "currency",
            }).substring(3),
        totalValue: selectedTotalValue,
        totalDebtValue: selectedTotalDebtValue,
        interestFree: selectedPaymentPlan.is_interest_free,
      },
      paymentPlan: cashGroup.payment_plan.map((payment) => {
        return {
          id: payment.plan_id,
          installmentNumber: payment.installment_number,
          isRecommendedInstallment:
            payment.installment_number === recommendedInstallmentNumber,
          installmentValue: format({
            value: Number(payment.installment_value),
            style: "currency",
          }).substring(3),
          totalValue: format({
            value: Number(payment.total_value),
            style: "currency",
          }).substring(3),
          totalDebtValue: format({
            value: Number(payment.total_debt_value),
            style: "currency",
          }).substring(3),
          interestFree: payment.is_interest_free,
        }
      }),
      contracts: cashGroup.contracts.map((contract) => {
        const id = contract.contract_number
          ? contract.contract_number
          : contract.external_contract_id

        const storagedContract = renegContracts?.find(
          (contractInfo) =>
            Number(id) === contractInfo.CustomData.contract_number
        )

        return {
          id,
          productCode: contract.product_code,
          title: contract.product_name,
          originalValue: contract.initial_value
            ? format({
                value: Number(contract.initial_value),
                style: "currency",
              }).substring(3)
            : null,
          currentValue: format({
            value: contract.value ? Number(contract.value) : null,
            style: "currency",
          }).substring(3),
          simulationInfo: storagedContract,
          desenrola: handleDesenrola(storagedContract?.CustomData, true),
          delayedDays: storagedContract?.CustomData?.delayed_days || 0,
        }
      }),
      recommendedInstallmentNumber,
    }
  })
}

export const InstallmentSimulation = (
  installment_simulation_response,
  isHotDeal,
  selectedPaymentPlans,
  hot_deal_response
) => {
  let totalOriginalValue = 0
  let totalCurrentValue = 0

  if (
    installment_simulation_response &&
    installment_simulation_response.summaries &&
    Array.isArray(installment_simulation_response?.summaries)
  ) {
    installment_simulation_response?.summaries.map((summaryGroup) => {
      totalOriginalValue += Number(summaryGroup.total_balance)
      totalCurrentValue += Number(summaryGroup.total_value)
    })
  }

  const hotDealDiscountPercentage =
    hot_deal_response?.simulation?.summary?.simulation_discount_percentage

  const installmentSummary = installment_simulation_response?.summary
    ? installment_simulation_response?.summary
    : installment_simulation_response?.installment_simulation_summary

  const debtGroup = hot_deal_response
    ? hot_deal_response
    : installment_simulation_response

  const installmentDebtsGroup = debtGroup?.debts_group
    ? debtGroup?.debts_group
    : debtGroup?.installment_debts_group

  const debtsGroups = FormatDebtsGroups(
    installmentDebtsGroup,
    isHotDeal,
    selectedPaymentPlans,
    installment_simulation_response
  )
  const { recommendedInstallmentNumber } = debtsGroups[0]

  const currentValue = recommendedInstallmentNumber
    ? debtsGroups.reduce(
        (acc, item) =>
          acc + convertStringToNumber(item.selectedPaymentPlan.totalValue),
        0
      )
    : installmentSummary?.simulation_final_value

  const originalValue = recommendedInstallmentNumber
    ? debtsGroups.reduce(
        (acc, item) =>
          acc + convertStringToNumber(item.selectedPaymentPlan.totalDebtValue),
        0
      )
    : installmentSummary?.simulation_initial_value

  return {
    originalValue: format({
      value: isHotDeal ? totalOriginalValue : originalValue,
      style: "currency",
    }).substring(3),
    currentValue: format({
      value: isHotDeal ? totalCurrentValue : currentValue,
      style: "currency",
    }).substring(3),
    originalTotalValue: format({
      value: isHotDeal
        ? installment_simulation_response?.summaries?.[0]?.total_balance
        : originalValue,
      style: "currency",
    }).substring(3),
    currentTotalValue: format({
      value: isHotDeal
        ? installment_simulation_response?.summaries?.[0]?.total_value
        : currentValue,
      style: "currency",
    }).substring(3),
    percent: Math.trunc(
      isHotDeal
        ? hotDealDiscountPercentage
        : installmentSummary.simulation_discount_percentage
    ),
    maxInstallment: isHotDeal
      ? installment_simulation_response?.summaries?.[0]?.installment_number
      : installmentSummary.max_installment_number,
    paymentDay: isHotDeal
      ? installment_simulation_response?.summaries?.[0]
          ?.other_installments_due_day
      : installmentSummary?.payment_day || null,
    debtsGroups,
    nonRenegotiableContracts:
      installment_simulation_response?.non_renegotiable_contracts?.map(
        (contract) => ({
          name: contract?.product_name,
          contractNumber:
            contract?.contract_number || contract.external_contract_id,
        })
      ) || [],
  }
}

export const CashSimulation = (cash_simulation_response) => {
  const cashSimulationSumary = cash_simulation_response?.summary
    ? cash_simulation_response?.summary
    : cash_simulation_response?.cash_simulation_summary
  const cashDebtsGroups = cash_simulation_response?.debts_group
    ? cash_simulation_response?.debts_group
    : cash_simulation_response?.cash_debts_group

  const contractsResponse = storage.session.getItem("contractsResponse")
  const renegContracts = contractsResponse?.origins?.reneg

  return {
    originalValue: format({
      value: cashSimulationSumary?.simulation_initial_value,
      style: "currency",
    }).substring(3),
    currentValue: format({
      value: cashSimulationSumary?.simulation_final_value,
      style: "currency",
    }).substring(3),
    percent: Math.trunc(cashSimulationSumary?.simulation_discount_percentage),
    debtsGroups: cashDebtsGroups?.map((cashGroup, index) => {
      return {
        simulationId: cashGroup.simulation_id,
        title: `Grupo ${index + 1}`,
        originalValue: format({
          value: Number(cashGroup.initial_value),
          style: "currency",
        }).substring(3),
        currentValue: format({
          value: Number(cashGroup.value),
          style: "currency",
        }).substring(3),
        percent: Math.trunc(Number(cashGroup.percentual_discount)),
        availableDates: cashGroup.due_date.map((date) => dateFormat(date)),
        selectedDate: dateFormat(cashGroup.default_date),
        defaultDate: dateFormat(cashGroup.default_date),
        maxDate: dateFormat(cashGroup.max_date),
        minDate: dateFormat(cashGroup.min_date),
        contracts: cashGroup.contracts.map((contract) => {
          const id = contract.contract_number
            ? contract.contract_number
            : contract.external_contract_id

          const storagedContract = renegContracts?.find(
            (contractInfo) =>
              Number(id) === contractInfo.CustomData.contract_number
          )

          return {
            id,
            productCode: contract.product_code,
            title: contract.product_name,
            originalValue: format({
              value: Number(contract.initial_value),
              style: "currency",
            }).substring(3),
            currentValue: format({
              value: Number(contract.value),
              style: "currency",
            }).substring(3),
            simulationInfo: storagedContract,
            desenrola: handleDesenrola(storagedContract?.CustomData),
            delayedDays: storagedContract?.CustomData?.delayed_days || 0,
          }
        }),
      }
    }),
    nonRenegotiableContracts:
      cash_simulation_response?.non_renegotiable_contracts?.map((contract) => ({
        name: contract?.product_name,
        contractNumber: contract?.contract_number,
      })) || [],
  }
}

export const DelayedSimulation = (delayed_simulation_response = {}) => {
  const {
    cash_simulation_summary: cash_simulation_summary_delayed = {},
    cash_debts_group: cash_debts_group_delayed = [],
    summary: cash_simulation_summary_delayed_integradora = {},
    debts_group: cash_debts_group_delayed_integradora = [],
  } = delayed_simulation_response || {}

  const delayedSummary = cash_simulation_summary_delayed_integradora
    ? cash_simulation_summary_delayed_integradora
    : cash_simulation_summary_delayed

  const delayedDebtsGroup = cash_debts_group_delayed_integradora
    ? cash_debts_group_delayed_integradora
    : cash_debts_group_delayed

  const contractsResponse = storage.session.getItem("contractsResponse")
  const renegContracts = contractsResponse?.origins?.reneg

  return {
    originalValue: format({
      value: delayedSummary?.simulation_initial_value,
      style: "currency",
    }).substring(3),
    currentValue: format({
      value: delayedSummary?.simulation_final_value,
      style: "currency",
    }).substring(3),
    percent: Math.trunc(delayedSummary?.simulation_discount_percentage),
    debtsGroups: delayedDebtsGroup?.map((cashGroup, index) => {
      return {
        simulationId: cashGroup.simulation_id,
        title: `Grupo ${index + 1}`,
        originalValue: format({
          value: Number(cashGroup.initial_value),
          style: "currency",
        }).substring(3),
        currentValue: format({
          value: Number(cashGroup.value),
          style: "currency",
        }).substring(3),
        percent: Math.trunc(Number(cashGroup.percentual_discount)),
        availableDates: cashGroup.due_date.map((date) => dateFormat(date)),
        selectedDate: dateFormat(cashGroup.default_date),
        defaultDate: dateFormat(cashGroup.default_date),
        maxDate: dateFormat(cashGroup.max_date),
        minDate: dateFormat(cashGroup.min_date),
        contracts: cashGroup.contracts.map((contract) => {
          const id = contract.contract_number
            ? contract.contract_number
            : contract.external_contract_id

          const storagedContract = renegContracts?.find(
            (contractInfo) =>
              Number(id) === contractInfo.CustomData.contract_number
          )

          return {
            id,
            productCode: contract.product_code,
            title: contract.product_name,
            originalValue: format({
              value: Number(contract.initial_value),
              style: "currency",
            }).substring(3),
            currentValue: format({
              value: Number(contract.value),
              style: "currency",
            }).substring(3),
            simulationInfo: storagedContract,
            overdueInstallments: contract.overdue_installments,
            overdueInstallmentValue: contract.overdue_installment_value,
            maxOverdueInstallment: contract.max_overdue_installment,
            minOverdueInstallment: contract.min_overdue_installment,
            desenrola: handleDesenrola(storagedContract?.CustomData),
            delayedDays: storagedContract?.CustomData?.delayed_days || 0,
          }
        }),
      }
    }),
    nonRenegotiableContracts:
      delayedDebtsGroup?.non_renegotiable_contracts?.map((contract) => ({
        name: contract?.product_name,
        contractNumber:
          contract?.contract_number || contract.external_contract_id,
      })) || [],
  }
}

export const CashSimulationDetail = (
  cash_simulation_response = {},
  delayed_simulation_response = {},
  installment_simulation_response = {},
  hot_deal_response = {}
) => {
  // .summary is from integradora service

  const cashDetailSummary = cash_simulation_response?.summary
    ? cash_simulation_response?.summary
    : cash_simulation_response?.cash_simulation_summary

  const delayedDetailSummary = delayed_simulation_response?.summary
    ? delayed_simulation_response?.summary
    : delayed_simulation_response?.cash_simulation_summary

  const installmentDetailSummary = installment_simulation_response?.summary
    ? installment_simulation_response?.summary
    : installment_simulation_response?.installment_simulation_summary

  const hotDealDetailSummary = hot_deal_response?.summary
    ? hot_deal_response?.summary
    : hot_deal_response?.simulation?.summary ||
      hot_deal_response?.simulation?.installment_simulation_summary

  return {
    cashSimulationSumary: cashDetailSummary
      ? CashSimulation(cash_simulation_response)
      : null,
    delayedSimulationSumary: delayedDetailSummary
      ? DelayedSimulation(delayed_simulation_response)
      : null,
    installmentSimulationSummary: installmentDetailSummary
      ? InstallmentSimulation(installment_simulation_response)
      : null,
    hotDealSimulationSummary: hotDealDetailSummary
      ? {
          ...InstallmentSimulation(
            hotDealDetailSummary,
            true,
            null,
            hot_deal_response
          ),
          interestFree: hotDealDetailSummary[0]?.is_interest_free,
        }
      : null,
  }
}
